/*-------------------
DocumentByLine
--------------------*/

.documentbyline {
  display: block;
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;
  color: $grey;
  .effective,
  .modified {
    font-size: 0.8rem;
    font-weight: 300;
  }

  .author {
    font-size: 0.8rem;
    font-weight: 300;
  }

  &.edit {
    font-size: 0.8rem;
    font-weight: 300;
  }
}
