#main .header-wrapper .header .search-wrapper .search .search-bar {
  background-color: var(--main-color);
  .searchbox {
    border-bottom: 2px solid var(--reverse-color);
    input {
      background-color: var(--main-color-light);
      color: var(--reverse-color);
      &::placeholder {
        color: var(--reverse-color);
      }
      &::selection {
        background-color: var(--reverse-color);
        color: var(--main-color);
      }
    }
    > button svg {
      fill: var(--reverse-color) !important;
    }
  }
}
