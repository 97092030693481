.header-wrapper .header {
  .logo-nav-wrapper {
    padding-bottom: 5px;
  }
}

.navigation {
  .item {
    font-family: sans-serif;
    font-size: 16px;
    text-transform: uppercase !important;

    &.active,
    &:hover {
      color: var(--main-color);
      &::before {
        border-bottom: 9px solid var(--main-color);
      }
    }
  }

  ul.desktop-menu .submenu-wrapper {
    .submenu {
      background: var(--main-color);

      &.active {
        .close {
          background: var(--main-color);
          color: var(--reverse-color);
        }
      }

      .submenu-inner {
        h2 {
          color: var(--reverse-color);
        }

        .submenu-header {
          h2 {
            margin-left: 0px;
            font-weight: 700;
          }
        }

        .subitem-wrapper {
          border-left: 1px solid var(--reverse-color);

          > a,
          li > a {
            color: var(--reverse-color);

            span {
              &:hover {
                border-bottom: 1px solid var(--reverse-color);
              }
              color: var(--reverse-color);
            }
          }
        }
      }
    }
  }
}
