/*-------------------
CalendarDate Element
--------------------*/
.block.listing.events-summary {
  .listing-item a {
    align-items: center;
  }
}

.cal_wrapper {
  display: flex;
  min-width: 150px;
  min-height: 100%;
  flex-direction: row;
  justify-content: center;
}
.cal_date {
  display: flex;
  overflow: hidden;
  width: 120px;
  height: 120px;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  border-radius: 5px;
  margin: 0 24px 12px 0;
  background-color: var(--alternate-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.17);
  color: var(--reverse-color);
  float: left;
  text-align: center;

  .cal_day {
    display: block;
    padding-bottom: 5px;
    font-size: 28px;
    font-weight: 900;
  }

  .cal_month {
    display: block;
    padding-bottom: 5px;
    font-size: 24px;
    font-weight: 300;
  }

  .cal_year {
    display: block;
    font-size: 24px;
    font-weight: 300;
  }
}
