.has--backgroundColor--green {
  background: var(--main-color);
}

#page-add .block-editor-accordion.has--backgroundColor--green,
#page-edit .block-editor-accordion.has--backgroundColor--green {
  .accordion.ui.fluid.styled {
    background: var(--main-color);
  }
}

.has--backgroundColor--blue {
  background: var(--alternate-color);
}

#page-add .block-editor-accordion.has--backgroundColor--blue,
#page-edit .block-editor-accordion.has--backgroundColor--blue {
  .accordion.ui.fluid.styled {
    background: var(--alternate-color);
  }
}
