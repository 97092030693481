:root {
  --main-color: rgb(11, 152, 58);
  --reverse-color: rgb(240, 240, 240);
  --alternate-color: rgb(54, 174, 221);
  --main-color-light: rgba(11, 152, 58, 0.526);
  --text-color: #333;
  --narrow-container-width: 1024px;
  --default-container-width: 1024px;
  --layout-container-width: 1440px;
  --breadcrumbs-text-color: var(--reverse-color);
}
