.block.gridBlock .block.image,
#page-edit .block-editor-gridBlock .block.gridBlock .block.image {
  figure::after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 0px;
    background: transparent;
    content: '';
  }
}

.block.image.portrait,
#page-edit .block.image.portrait {
  figure.portrait {
    display: inline;
    height: 100%;
    vertical-align: middle;
    img.responsive {
      aspect-ratio: auto !important;
      object-fit: cover;
    }
  }
}
