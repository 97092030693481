#footer {
  .footer {
    padding-right: 120px;
    padding-left: 120px;
    border-top: thick double var(--reverse-color);
    background: var(--main-color);
    color: var(--reverse-color);

    @media only screen and (max-width: $tablet-breakpoint) {
      padding-right: 30px;
      padding-left: 30px;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .address-contact-container {
        display: flex;
        justify-content: space-between;
        gap: 25px;

        @media only screen and (max-width: $tablet-breakpoint) {
          flex-flow: column;
          justify-content: flex-start;
        }

        .ecosistema {
          min-width: 20%;

          .ecoLink {
            img.ecoLogo {
              max-width: 200px;
            }
          }
        }
        p.headline {
          padding-bottom: 15px;
          border-bottom: 1px solid $white;
          margin-bottom: 18px;
          font-weight: 900;
          line-height: 36px;
        }

        p,
        address {
          text-align: left;
        }
        .networks {
          .network {
            padding: 0 10px;
          }
          img.networkLogo {
            width: 100px;
            height: 100px;
          }
        }
        .socialNetworks {
          min-width: 20%;
          a.external.social-network.item {
            svg {
              fill: var(--reverse-color) !important;
            }
          }
        }
      }
    }
  }
  .poweredBy {
    padding-right: 120px;
    padding-left: 120px;
    border-top: 2px solid var(--reverse-color);
    background: var(--main-color);
    color: var(--reverse-color);
    .signature {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 0.7rem;
      font-weight: 600;
      .signatureLogo {
        width: 100px;
      }
    }
  }
}
